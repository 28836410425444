<template>
    <div>
        <div style="display: flex;">
            <div style="width: 50%;">
                <img src="" alt="">
            </div>
            <div style="width: 50%;">
                <div style="display: flex; justify-content:space-around; margin-top: 20px;">
                    <div>
                        <div style="font-size: 16px; color: #595959; font-weight: 400;">工作状态
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div style="font-size: 36px; color: #3366ff; font-weight: 500; margin-top: 10px;">运行</div>
                    </div>
                    <div>
                        <div style="font-size: 16px; color: #595959; font-weight: 400;">入网状态&nbsp;&nbsp;&nbsp;</div>
                        <div style="font-size: 36px; color: #1f1f1f; font-weight: 500;margin-top: 10px;">并网</div>
                    </div>
                </div>
                <div style="display: flex; justify-content:space-around;margin-top: 20%;">
                    <div>
                        <div style="font-size: 16px; color: #595959; font-weight: 400;">机内空气温度</div>
                        <div style="font-size: 36px; color: #51A34B; font-weight: 500;margin-top: 10px;">44</div>
                    </div>
                    <div>
                        <div style="font-size: 16px; color: #595959; font-weight: 400;">当日发电量</div>
                        <div style="font-size: 36px; color: #1F1F1F; font-weight: 500;margin-top: 10px;">102 <span
                                style="font-size:12px; color: #9f9f9f;">kWh</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex; margin-top: 30px;">
            <div style="width: 50%; font-size: 14px; color: #1F1F1F;">
                <div v-for="(item, index) in leftData" :key="index" style="display: flex; position: relative; margin-top: 20px;">
                    <span>{{ item.name }}</span>
                    <el-progress :percentage="item.percentage" :color="item.color" style="width: 60%; position: absolute;right: 10px;"></el-progress>
                </div>
            </div>
            <div style="width: 50%; font-size: 14px; color: #1F1F1F;">
                <div v-for="(item, index) in rightData" :key="index" style="position: relative;display: flex; margin-top: 20px;">
                    <span>{{ item.name }}</span>
                    <el-progress :percentage="item.percentage" :color="item.color" style="width: 60%; position: absolute;right: 10px;"></el-progress>
                </div>
            </div>
            <div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            leftData: [
                {
                    name: 'A相电流(A)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: 'B相电流(A)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: 'C相电流(A)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: '额定输出功率(kW)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: '电网频率(Hz)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: '设备利用率(%)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: '总并网运行时间(h)',
                    percentage: '20',
                    color: '#25B131'
                },
            ],
            rightData: [
                {
                    name: 'A相电压(V)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: 'B相电压(V)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: 'C相电压(V)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: '有功功率(kW)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: '等价发电时间(h)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: '逆变器效率(%)',
                    percentage: '20',
                    color: '#25B131'
                },
                {
                    name: '累计发电量(kWh)',
                    percentage: '20',
                    color: '#25B131'
                },
            ]
        }
    },
}
</script>