<template>
    <div class="powerChart1">
        <div class="card-content2" style="height: 200px; width: 200px; margin: auto;">
            <chartBoard ref="main_modle13" :option="chartOption1" />
        </div>
        <div class="subTable">
            <div class="subTable-list" v-for="(item, idx) in dataInfo" :key="idx">
                <span><i :style="{ background: colorList[idx] }"> </i>{{ item.name }}</span>
                <span>{{ item.value || 0 }} 坐</span>
                <span class="percent-box" :class="[item.percent >= 0 ? 'up' : 'decline']">{{ item.percent || 0 }} %</span>

            </div>
        </div>

    </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
    name: 'powerChart1',
    components: {
        chartBoard: () => import('@/component/chartBoard.vue'),
    },
    props: ['value', 'shuaxin', 'Height'],
    data() {
        return {
            vm: null,
            dataInfo: [],
            chartOption1: {},
            colorList: ["#DBDBDB", "#FF7300", "#F03040"]
        }
    },

    methods: {
        //每月报警预警数
        getData() {
            var data = [
                {
                    name: "正常电站",
                    value: 15,
                    percent: 80
                },
                {
                    name: "故障电站",
                    value: 1,
                    percent: 5
                },
                {
                    name: "断点电站",
                    value: 1,
                    percent: 5
                },
            ]
            this.dataInfo = data
            // this.$axios.post(this.basic_url + "/maximo/intmaximo/app/getAssetStatistics", {
            //     "PERSONID": this.$store.state.currentuser.PERSONID,
            //     }).then(res => {
            //     if (res.data.code == '1') {
            //         for (var i = 0; i < res.data.data.length;i++) {
            //             this.alarmWarningData1.push({
            //                 name: res.data.data[i].UDRUNSTATUS,
            //             })
            //             this.alarmWarningData2.push({
            //                 value: res.data.data[i].COUNT,
            //             })
            //             this.alarmWarningData3.push({
            //                 name: res.data.data[i].UDRUNSTATUS,
            //                 value: res.data.data[i].COUNT,
            //             })
            //         }
            //         this.$nextTick(function () {
            //             this.initEchart();//调用统计图
            //         })
            //     }
            // }).catch(err => {});
        },
        initEchart() {
            var _this = this;
            // this.vm = echarts.init(this.$refs.powerChart1, "macarons");
            var seriesData = this.dataInfo
            var sum = seriesData.reduce(function (prev, cur) {
                return prev + cur.value;
            }, 0)
            var colorList = this.colorList
            var option13 = {
                title: [
                    {
                        text: "总计(坐)",
                        subtext: sum,
                        textStyle: {
                            fontSize: 12,
                            color: "black",
                        },
                        subtextStyle: {
                            fontSize: 18,
                            color: "black",
                            fontWeight: '600',
                        },
                        textAlign: "center",

                        x: "50%",
                        y: "25%",
                    },
                    {
                        subtext: "电站总数",
                        subtextStyle: {
                            fontSize: 12,
                            color: "black",
                        },
                        textAlign: "center",
                        x: "50%",
                        y: "45%",
                    },
                ],
                tooltip: {
                    trigger: 'item',
                    extraCssText: 'width:132px;height:40px;',
                },
                series: [{
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                return colorList[params.dataIndex]
                            },
                            borderColor: '#172659',
                            borderWidth: 0
                        }
                    },
                    type: 'pie',
                    radius: ['55%', '70%'],
                    center: ["52%", "40%"],
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: '#34569D'
                            }
                        }
                    },
                    label: {
                        normal: {
                            show: false,
                            formatter: params => {
                                return (
                                    '{name|' + params.name + '}{percent|' + params.percent.toFixed(0) + '%}'
                                );
                            },
                            padding: [0, -5, 0, -5],
                        }
                    },
                    data: seriesData
                }
                ]
            }
            this.updateChart('chart1', 'chartOption1', option13)
        },
        updateChart: function (refName, optionName, option13 = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option13
            setTimeout(() => {
                this.$refs.main_modle13.updateChartView()
            }, 500)
        },
        resize() {
            this.$forceUpdate()
            this.vm.resize()
        }
    },
    watch: {
        // Height(val, val2) {//titleDec即为父组件的值，val参数为值
        //     this.$nextTick().then(() => {
        //         this.vm.resize()
        //     })
        // },
        // Width(val, val2) {//titleDec即为父组件的值，val参数为值
        //     this.$nextTick().then(() => {
        //         this.vm.resize()
        //     })
        // },
        value() {//titleDec即为父组件的值，val参数为值
            this.initEchart()
        },
        shuaxin() {//titleDec即为父组件的值，val参数为值
            this.vm.resize()
        },
    },
    mounted() {
        this.getData()
        this.initEchart()
    }
}
</script>

<style lang="less" scoped>
.powerChart1 {
    display: flex;
    flex-direction: column;
}

.chartBox {
    width: inherit;
    flex: 1;
}

.subTable {
    height: 100px;
    display: flex;
    flex-direction: column;

    .subTable-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        height: 24px;
        border-bottom: 1px solid #ebebeb;
        margin-top: 8px;

        &:last-child {
            border-bottom: none;
        }

        span {
            display: flex;
            // align-items: center;
        }

        i {
            width: 3px;
            height: 20px;
            margin-right: 6px;
            display: inline-block;
        }

        // .percent-box {
        //     border-radius: 35%;
        //     padding: 0 8px;
        //     height: 18px;
        //     line-height: 18px;
        // }
        // .up {
        //     background: #fdeaeb;
        //     color: #f03040;
        // }
        // .decline {
        //     background: #e8f5ea;
        //     color: #21a432;
        // }
    }
}
</style>