<template>
    <div ref="text_modle13" style="width: 100%;">
        <div class="card-content2" style="height: 250px;">
            <chartBoard ref="main_modle13" :option="chartOption1" />
        </div>
        <div style="position: absolute; top: 30%; right: 20px;">
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #1191E6; margin-right: 4px;"></span><span>1#站点</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #7ED6C6; margin-right: 4px;"></span><span>2#站点</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #98D694; margin-right: 4px;"></span><span>3#站点</span>
            </div>
            <div style="margin: 10px;">
                <span
                    style="display: inline-block; width: 12px; border-bottom: 4px solid #EDE56F; margin-right: 4px;"></span><span>4#站点</span>
            </div>
        </div>
    </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import { color } from 'echarts';
export default {
    name: 'detailsmask001',
    components: {
        chartBoard: () => import('@/component/chartBoard.vue'),
    },
    // props: ['value', 'shuaxin', 'Height', 'Width'],
    data() {
        return {
            checked1: true,
            vm: null,
            chartOption1: {},
            dialogCategory: false,
        }
    },

    methods: {
        a() {
            console.log(this.checked1)
        },
        initEchart() {
            // this.vm = echarts.init(this.$refs.main_modle13, "macarons");
            var option13 = {
                backgroundColor: 'white',
                grid: {
                    left: '0%',
                    right: '15%',
                    bottom: '0%',
                    top: '20%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    }
                },
                yAxis: {
                    name: "单位（%）",
                    axisTick: {
                        show: true
                    },
                    nameTextStyle: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    // 控制数据参数最大值和最小值
                    interval: 20,
                    max: 100

                },
                // 控住柱状图样式
                series: [
                    {
                        name: '2011',
                        type: 'line',
                        itemStyle: {
                            color: "#1191E6"
                        },
                        data: [67, 89, 34, 70, 74, 30]
                    },
                    {
                        name: '2011',
                        type: 'line',
                        itemStyle: {
                            color: "#7ED6C6"
                        },
                        data: [23, 29, 24, 40, 14, 10]
                    },
                    {
                        name: '2011',
                        type: 'line',
                        itemStyle: {
                            color: "#98D694"
                        },
                        data: [12, 28, 20, 49, 17, 12]
                    },
                    {
                        name: '2011',
                        type: 'line',
                        itemStyle: {
                            color: "#EDE56F"
                        },
                        data: [63, 49, 23, 70, 14, 10]
                    },
                ]
            };
            this.updateChart('chart1', 'chartOption1', option13)
        },
        updateChart: function (refName, optionName, option13 = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option13
            setTimeout(() => {
                this.$refs.main_modle13.updateChartView()
            }, 500)
        },
    },
    watch: {
        Height(val, val2) {//titleDec即为父组件的值，val参数为值

            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        Width(val, val2) {//titleDec即为父组件的值，val参数为值
            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        value() {//titleDec即为父组件的值，val参数为值
            this.initEchart()
        },
        shuaxin() {//titleDec即为父组件的值，val参数为值
            this.vm.resize()
        },
    },
    mounted() {
        // this.getData()
        this.initEchart()
        // window.addEventListener('resize', () => {
        //     this.vm.resize()
        // })
    }
}
</script>

<style scoped>
.des {
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 36px);
    z-index: 999;
}

.text17 {
    font-size: 17px;
    color: #202224;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

/deep/.des .el-icon-bottom:before {
    color: rgb(0, 182, 155);
}
</style>