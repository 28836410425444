<template>
    <div class="equipmentCom12">
        <div style="width: 100%;">
            <div style="margin: auto; width: 100px;"><img src="../../assets/icon/51.svg" alt=""></div>
            <!-- <img src="../../assets/icon/3.svg" alt=""> -->
            <div  style="background-color: #3366FF; border-radius: 4px; width: 200px; padding: 10px; margin-top: 20px;" v-if="this.state == '运行中'" @click="newlyAdd">
                <div><span style="color: #FFFFFF; font-weight: 700; font-size: 14px;">PV-10010</span> <span
                        style="color: #FFFFFF; font-weight: 400; font-size: 14px; margin-left: 70px; cursor: pointer;">详情</span></div>
                <div style="color: #FFFFFF; font-size: 24px; font-weight: 700; margin-top: 15px;">{{ state }}</div>
                <div style="color: #FFFFFF; font-weight: 400; font-size: 14px; margin-top: 15px;">设备利用率67.7%</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            state: "运行中",
            dialogVisible: false,
            value:true,
        }
    },
    props: {
        dialogVisibleClick:Function,
    },
    methods: {
        newlyAdd(){
            this.dialogVisibleClick(this.value)
        }
    },
}
</script>
<style lang="less">
.dableBOx {
    margin-top: 20px;
    background-color: #FFFFFF;

    div {
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
    }
}
</style>