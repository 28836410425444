<template>
    <div id="energy" style="margin-top: 10px;height: inherit;overflow: auto;">
        <div>
            <GridLayout :col-num="12"
                :style="{ position: 'relative',top:'-10px' }"
                :is-draggable="draggable" :is-resizable="resizable" :layout="layout" :responsive="responsive"
                :row-height="60" :use-css-transforms="true" :vertical-compact="true">
                <GridItem v-for="(item, indexVar) in layout" :key="indexVar" :h="item.h" :i="item.i" :minW="item.minw"
                    :minH="item.minh" :static="item.static" :w="item.w" :x="item.x" :y="item.y" :isDraggable="item.Draggable">
                    <div style="width: 100%; height: 100%; background: white">
                        <div style="width:100%" v-if="item.i == 1">
                            <bulletinBoard style="width:100%;" :top="true" title="电站发电量统计" :whiteT="true">
                                <div style="display:flex;margin-bottom: 25px;">
                                    <div style="margin-right: 80px;">
                                        <div
                                            style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                            今年总发电量</div>
                                        <div
                                            style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#165DFF">
                                            51,276.33 <span style="font-size: 14px; color: #000000;">kWh</span></div>
                                        <div
                                            style="display: flex;justify-content: center;margin-top: 15px;position: relative;left: -11px;    align-items: center;">
                                            <div>
                                                <i style="font-size: 13px;position: relative;top: -1px;color: #F53F3F;"
                                                    class="el-icon-caret-bottom"></i>
                                            </div>
                                            <div style="font-size: 13px;color: #F53F3F;">11.2%</div>
                                            <div style="font-size: 12px;margin-left: 4px;color:#b1adad">较上年</div>
                                        </div>
                                    </div>
                                    <div style="margin-right: 80px;">
                                        <div
                                            style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                            全年总发电量</div>
                                        <div
                                            style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#165DFF">
                                            5270.33 <span style="font-size: 14px; color: #000000;">kWh</span></div>
                                        <div
                                            style="display: flex;justify-content: center;margin-top: 15px;position: relative;left: -11px;    align-items: center;">
                                            <div>
                                                <i style="font-size: 13px;position: relative;top: -1px;color: #F53F3F;"
                                                    class="el-icon-caret-bottom"></i>
                                            </div>
                                            <div style="font-size: 13px;color: #F53F3F;">11.2%</div>
                                            <div style="font-size: 12px;margin-left: 4px;color:#b1adad">较上年</div>
                                        </div>
                                    </div>
                                </div>
                                <equipmentCom1 style="width: 100%;height: 100%;"></equipmentCom1>
                            </bulletinBoard>

                        </div>
                        <div style="width:100%" v-if="item.i == 2">
                            <bulletinBoard style="width:100%;" :top="true" title="发电量排名" :whiteT="true">
                                <equipmentCom2 style="width: 100%;height: 100%;"></equipmentCom2>
                            </bulletinBoard>

                        </div>
                        <div style="width:100%" v-if="item.i == 3">
                            <bulletinBoard style="width:100%;" :top="true" title="等效利用小时数" :whiteT="true">
                                <div style="display:flex;margin-bottom: 25px;">
                                    <div style="margin-right: 50px;" v-for="(item, index) in siteData" :key="index">
                                        <div
                                            style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                            {{ item.name }}</div>
                                        <div
                                            style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#000000">
                                            {{ item.num }} <span style="font-size: 14px; color: #000000;">小时</span>
                                        </div>
                                    </div>
                                </div>
                                <equipmentCom3 style="width: 100%;height: 100%;"></equipmentCom3>
                            </bulletinBoard>

                        </div>
                        <div style="width:100%" v-if="item.i == 4">
                            <bulletinBoard style="width:100%;" :top="true" title="设备利用率" :whiteT="true">
                                <div style="display:flex;margin-bottom: 25px;">
                                    <div style="margin-right: 50px;" v-for="(item, index) in siteData1" :key="index">
                                        <div
                                            style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">
                                            {{ item.name }}</div>
                                        <div
                                            style="font-size: 24px;font-weight: 600;text-align: center;position: relative;left: -5px;color:#000000">
                                            {{ item.num }}
                                        </div>
                                    </div>
                                </div>
                                <equipmentCom4 style="width: 100%;height: 100%;"></equipmentCom4>
                            </bulletinBoard>

                        </div>
                        <div style="width:100%" v-if="item.i == 5">
                            <bulletinBoard style="width:100%;" :top="false" title="光纤逆变器实时数据" :whiteT="true">
                                <equipmentCom5 style="width: 100%;height: 100%;"></equipmentCom5>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 6">
                            <bulletinBoard style="width:100%;" :top="false" title="设备台账" :whiteT="true">
                                <equipmentCom6 style="width: 100%;height: 100%;"></equipmentCom6>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 7">
                            <bulletinBoard style="width:100%;" :top="false" title="电站状态" :whiteT="true">
                                <equipmentCom7 style="width: 100%;height: 100%;"></equipmentCom7>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 8">
                            <bulletinBoard style="width:100%;" :top="false" title="光伏井网电站列表" :whiteT="true">
                                <equipmentCom8 style="width: 100%;height: 100%;"></equipmentCom8>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 9">
                            <bulletinBoard style="width:100%;" :top="false" title="光纤电站状态" :whiteT="true">
                                <equipmentCom9 style="width: 100%;height: 100%;"></equipmentCom9>
                            </bulletinBoard>
                        </div>
                        <div style="width:100%" v-if="item.i == 10">
                            <bulletinBoard style="width:100%;" :top="false" title="当前告警" :whiteT="true">
                                <equipmentCom10 style="width: 100%;height: 100%;"></equipmentCom10>
                            </bulletinBoard>
                        </div>
                        <!-- <div style="width:100%" v-if="item.i == 11">
                            <bulletinBoard style="width:100%;" :top="false" title="气象信息" :whiteT="true">
                                <equipmentCom11 style="width: 100%;height: 100%;"></equipmentCom11>
                            </bulletinBoard>
                        </div> -->
                        <div style="width:100%" v-if="item.i == 12">
                            <bulletinBoard style="width:100%;" :top="false" title="当前告警" :whiteT="true">
                                <equipmentCom12 style="width: 100%;height: 100%;"></equipmentCom12>
                            </bulletinBoard>
                        </div>
                    </div>
                </GridItem>
            </GridLayout>
        </div>
    </div>
</template>
<script>
import bulletinBoard from "@/component/bulletinBoard";
import equipmentCom1 from "@/component/equipmentCom/equipmentCom1";
import equipmentCom2 from "@/component/equipmentCom/equipmentCom2";
import equipmentCom3 from "@/component/equipmentCom/equipmentCom3";
import equipmentCom4 from "@/component/equipmentCom/equipmentCom4";
import equipmentCom5 from "@/component/equipmentCom/equipmentCom5";
import equipmentCom6 from "@/component/equipmentCom/equipmentCom6";
import equipmentCom7 from "@/component/equipmentCom/equipmentCom7";
import equipmentCom8 from "@/component/equipmentCom/equipmentCom8";
import equipmentCom9 from "@/component/equipmentCom/equipmentCom9";
import equipmentCom10 from "@/component/equipmentCom/equipmentCom10";
// import equipmentCom11 from "@/component/equipmentCom/equipmentCom11";
import equipmentCom12 from "@/component/equipmentCom/equipmentCom12";
import { GridLayout, GridItem } from 'vue-grid-layout'
export default {
    components: { bulletinBoard, GridItem, GridLayout, equipmentCom1, equipmentCom2, equipmentCom3,equipmentCom4,equipmentCom5,equipmentCom6,equipmentCom7,equipmentCom8,equipmentCom9,equipmentCom10,equipmentCom12 },
    data() {
        return {
            siteData: [
                {
                    name: '1#站点',
                    num: "7,233"
                },
                {
                    name: '2#站点',
                    num: "7,233"
                },
                {
                    name: '3#站点',
                    num: "7,233"
                },
                {
                    name: '4#站点',
                    num: "7,233"
                },
            ],
            siteData1: [
                {
                    name: '1#站点',
                    num: "64.2%"
                },
                {
                    name: '2#站点',
                    num: "77.1%"
                },
                {
                    name: '3#站点',
                    num: "69.5%"
                },
                {
                    name: '4#站点',
                    num: "70.2%"
                },
            ],
            layout: [
                { x: 0, y: 5, w: 6, h: 6, i: 1, minw: 3, minh: 5 },
                { x: 6, y: 5, w: 6, h: 6, i: 2, minw: 3, minh: 5 },
                { x: 0, y: 11, w: 6, h: 6, i: 3, minw: 3, minh: 5 },
                { x: 6, y: 11, w: 6, h: 6, i: 4, minw: 3, minh: 5 },
                { x: 0, y: 17, w: 6, h: 8, i: 5, minw: 3, minh: 5 },
                { x: 6, y: 25, w: 6, h: 6, i: 6, minw: 3, minh: 5 },
                { x: 0, y: 25, w: 6, h: 6, i: 7, minw: 3, minh: 5 },
                { x: 0, y: 31, w: 12, h: 6, i: 8, minw: 3, minh: 5 },
                { x: 0, y: 37, w: 3, h: 6, i: 9, minw: 3, minh: 5 },
                { x: 3, y: 37, w: 3, h: 6, i: 10, minw: 3, minh: 5 },
                // { x: 6, y: 37, w: 3, h: 6, i: 11, minw: 3, minh: 5 },
                { x: 0, y: 43, w: 6, h: 6, i: 12, minw: 3, minh: 5 },
            ],
            responsive: true,
            draggable: true,
            resizable: true,
        };
    },
    methods: {
        // resized(i, newH, newW, newHPx, newWPx) {
        //     console.log("RESIZE i=" + i + ", H=" + newH + ", W=" + newW + ", H(px)=" + newHPx + ", W(px)=" + newWPx)
        //     if (i == 1) {
        //         this.$nextTick().then(() => {
        //             this.Threewidth = newWPx - 40 + 'px'
        //             this.ThreeHeight = newHPx - 120 + 'px'
        //         })
        //     }
        //     if (i == 2) {
        //         this.$nextTick().then(() => {
        //             this.ESNineWidth = newWPx - 200 + 'px'
        //             this.ESNineHeight = newHPx - 200 + 'px'
        //         })
        //     }
        //     if (i == 5) {
        //         this.$nextTick().then(() => {
        //             this.ESTenWidth = newWPx - 120 + 'px'
        //             this.ESTenHeight = newHPx - 200 + 'px'
        //         })
        //     }
        //     if (i == 8) {
        //         this.$nextTick().then(() => {
        //             this.ESFourWidth = newWPx - 120 + 'px'
        //             this.ESFourHeight = newHPx - 100 + 'px'
        //         })
        //     }
        //     if (i == 16) {
        //         this.$nextTick().then(() => {
        //             this.QualityOverviewWidth = newWPx - 0 + 'px'
        //             this.QualityOverviewHeight = newHPx - 0 + 'px'
        //         })
        //     }
        // },

    },
};
</script>
<style lang="less">

</style>