<template>
    <div style="margin-top: 3%;">
        <div style="display:flex;margin-bottom: 25px;">
            <div style=" width: 40%;margin-left: 20px;">
                <div style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">当前光纤发电功率</div>
                <div
                    style="font-size: 24px;font-weight: 600;text-align: left;position: relative; color:#000000">
                    136<span style="font-size: 14px; color: #000000;">kw</span>
                </div>
            </div>
            <div style=" width: 40%;">
                <div style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">当日光伏发电量</div>
                <div
                    style="font-size: 24px;font-weight: 600;text-align: left;position: relative; color:#000000">
                    597<span style="font-size: 14px; color: #000000;">kWh</span>
                </div>
            </div>
            <div style="">
                <div style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">总光伏发电量</div>
                <div
                    style="font-size: 24px;font-weight: 600;text-align: left;position: relative; color:#000000">
                    81,429<span style="font-size: 14px; color: #000000;">kWh</span>
                </div>
            </div>
        </div>
        <div style="display:flex;margin-bottom: 25px; ">
            <div style="margin-left: 20px; width: 40%;">
                <div style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">当前收益</div>
                <div
                    style="font-size: 24px;font-weight: 600;text-align: left;position: relative; color:#000000">
                    597<span style="font-size: 14px; color: #000000;">元</span>
                </div>
            </div>
            <div style="width: 40%;">
                <div style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">当前设备利用率</div>
                <div
                    style="font-size: 24px;font-weight: 600;text-align: left;position: relative; color:#000000">
                    90.0<span style="font-size: 14px; color: #000000;">%</span>
                </div>
            </div>
            <div style="">
                <div style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">总节约碳排</div>
                <div
                    style="font-size: 24px;font-weight: 600;text-align: left;position: relative; color:#000000">
                    190.0<span style="font-size: 14px; color: #000000;">t</span>
                </div>
            </div>
        </div>
        <div style="display:flex;margin-bottom: 25px;">
            <div style=" width: 40%;margin-left: 20px;">
                <div style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">当日等效利用小时</div>
                <div
                    style="font-size: 24px;font-weight: 600;text-align: left;position: relative; color:#000000">
                    8<span style="font-size: 14px; color: #000000;">小时</span>
                </div>
            </div>
            <div style=" width: 40%;">
                <div style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">总收益</div>
                <div
                    style="font-size: 24px;font-weight: 600;text-align: left;position: relative; color:#000000">
                    81,429<span style="font-size: 14px; color: #000000;">元</span>
                </div>
            </div>
            <div style="">
                <div style="font-size:14px;font-weight: 600;margin-top: 15px;margin-bottom: 13px;">当日系统效率</div>
                <div
                    style="font-size: 24px;font-weight: 600;text-align: left;position: relative; color:#000000">
                    100<span style="font-size: 14px; color: #000000;">%</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
}
</script>