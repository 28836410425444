<template>
    <div ref="text_modle13" style="display: flex; width: 100%;">
        <div>
            <template>
                <el-table :data="tableData">
                    <el-table-column prop="name" label="名称" width="100">
                    </el-table-column>
                    <el-table-column prop="id" label="排名" width="80">
                    </el-table-column>
                    <el-table-column prop="num" label="发电量">
                    </el-table-column>
                </el-table>
            </template>
        </div>
        <div class="card-content2" style="height: 320px;">
            <chartBoard ref="main_modle13" :option="chartOption1" />
        </div>
    </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import { color } from 'echarts';
export default {
    name: 'detailsmask001',
    components: {
        chartBoard: () => import('@/component/chartBoard.vue'),
    },
    // props: ['value', 'shuaxin', 'Height', 'Width'],
    data() {
        return {
            checked1: true,
            vm: null,
            chartOption1: {},
            Width: '90%',
            Height: '320px',
            dialogCategory: false,
            tableData: [
                {
                    name: '1#站点',
                    id: 1,
                    num: '14万kwh'
                },
                {
                    name: '1#站点',
                    id: 1,
                    num: '14万kwh'
                },
                {
                    name: '1#站点',
                    id: 1,
                    num: '14万kwh'
                },
                {
                    name: '1#站点',
                    id: 1,
                    num: '14万kwh'
                },
                {
                    name: '1#站点',
                    id: 1,
                    num: '14万kwh'
                },
                {
                    name: '1#站点',
                    id: 1,
                    num: '14万kwh'
                },
            ]
        }
    },

    methods: {
        initEchart() {
            // this.vm = echarts.init(this.$refs.main_modle13, "macarons");
            var option13 = {
                backgroundColor: 'white',
                grid: {
                    left: '0%',
                    right: '15%',
                    bottom: '0%',
                    top: '20%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    extraCssText: 'width:130px;height:40px;',
                    axisPointer: {
                        type: 'none'
                    },
                    formatter: function (params) {
                        return params[0].name + ' : '  + params[0].value
                    }
                },
                yAxis: {
                    type: 'category',
                    data: ['1#站点', '2#站点', '3#站点', '4#站点', '5#站点', '6#站点'],
                    axisTick: {
                        show: true
                    },
                    nameTextStyle: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    axisLabel: {
                        color: 'rgba(134,144,156,1)',
                        fontSize: 14
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(134,144,156,1)'
                        }
                    },
                    // 控制数据参数最大值和最小值
                    // interval: 200,
                    // max: 1000

                },
                // 控住柱状图样式
                series: [
                    {
                        name: '2011',
                        type: 'bar',
                        itemStyle: {
                            color: "#3366FF"
                        },
                        barWidth: 10,
                        data: [1203, 2489, 2034, 4970, 1744, 1230]
                    },
                ]
            };
            this.updateChart('chart1', 'chartOption1', option13)
        },
        updateChart: function (refName, optionName, option13 = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option13
            setTimeout(() => {
                this.$refs.main_modle13.updateChartView()
            }, 500)
        },
        handleWindowResize() {
            if (!this.vm) return;
            this.vm.resize();
        }

    },
    watch: {
        Height(val, val2) {//titleDec即为父组件的值，val参数为值

            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        Width(val, val2) {//titleDec即为父组件的值，val参数为值
            this.$nextTick().then(() => {
                console.log(val)
                console.log(val2)
                this.vm.resize()
            })
        },
        value() {//titleDec即为父组件的值，val参数为值
            this.initEchart()
        },
        shuaxin() {//titleDec即为父组件的值，val参数为值
            this.vm.resize()
        },
    },
    mounted() {
        // this.getData()
        this.initEchart()
        setTimeout(() => {
            this.handleWindowResize()
        }, 300);
    }
}
</script>

<style scoped>
.des {
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 36px);
    z-index: 999;
}

.text17 {
    font-size: 17px;
    color: #202224;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 15px;
}

/deep/.des .el-icon-bottom:before {
    color: rgb(0, 182, 155);
}
</style>