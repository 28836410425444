<template>
    <div class="equipmentCom8">
        <template>
            <el-table :data="tableData" style="width: 100%; height: 300px; overflow: auto;">
                <el-table-column prop="name" label="电站名称">
                </el-table-column>
                <el-table-column prop="data1" label="组串总装机容量（kWp）">
                </el-table-column>
                <el-table-column prop="data2" label="当前光伏发电功率（kW）">
                </el-table-column>
                <el-table-column prop="data3" label="当日等效利用小时（h）">
                </el-table-column>
                <el-table-column prop="data4" label="当日发电量（kWh）">
                </el-table-column>
                <el-table-column prop="data5" label="总发电量（kWh）">
                </el-table-column>
                <el-table-column prop="data6" label="当日系统效率(%)">
                </el-table-column>
                <el-table-column prop="data7" label="当日总辐照量（kWh/m2）">
                </el-table-column>
                <el-table-column prop="data8" label="并网时间">
                </el-table-column>
                <el-table-column prop="data9" label="当日设备利用率（%）">
                </el-table-column>
            </el-table>
        </template>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="currentPage2" :page-sizes="[10, 20, 50,]" :page-size="100"
            layout="sizes, prev, pager, next" :total="100">
        </el-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [
                {
                    name: "行政楼地下室配电间",
                    data1: "1200 ",
                    data2: "720",
                    data3: "2",
                    data4: "1420",
                    data5: "15630",
                    data6: "93.33% ",
                    data7: "1000",
                    data8: "2021-12-08",
                    data9: "92.78",
                },
                {
                    name: "行政楼地下室配电间",
                    data1: "1200 ",
                    data2: "720",
                    data3: "2",
                    data4: "1420",
                    data5: "15630",
                    data6: "93.33% ",
                    data7: "1000",
                    data8: "2021-12-08",
                    data9: "92.78",
                },
                {
                    name: "行政楼地下室配电间",
                    data1: "1200 ",
                    data2: "720",
                    data3: "2",
                    data4: "1420",
                    data5: "15630",
                    data6: "93.33% ",
                    data7: "1000",
                    data8: "2021-12-08",
                    data9: "92.78",
                },
                {
                    name: "行政楼地下室配电间",
                    data1: "1200 ",
                    data2: "720",
                    data3: "2",
                    data4: "1420",
                    data5: "15630",
                    data6: "93.33% ",
                    data7: "1000",
                    data8: "2021-12-08",
                    data9: "92.78",
                },
                {
                    name: "行政楼地下室配电间",
                    data1: "1200 ",
                    data2: "720",
                    data3: "2",
                    data4: "1420",
                    data5: "15630",
                    data6: "93.33% ",
                    data7: "1000",
                    data8: "2021-12-08",
                    data9: "92.78",
                },
                {
                    name: "行政楼地下室配电间",
                    data1: "1200 ",
                    data2: "720",
                    data3: "2",
                    data4: "1420",
                    data5: "15630",
                    data6: "93.33% ",
                    data7: "1000",
                    data8: "2021-12-08",
                    data9: "92.78",
                },
                {
                    name: "行政楼地下室配电间",
                    data1: "1200 ",
                    data2: "720",
                    data3: "2",
                    data4: "1420",
                    data5: "15630",
                    data6: "93.33% ",
                    data7: "1000",
                    data8: "2021-12-08",
                    data9: "92.78",
                },
                {
                    name: "行政楼地下室配电间",
                    data1: "1200 ",
                    data2: "720",
                    data3: "2",
                    data4: "1420",
                    data5: "15630",
                    data6: "93.33% ",
                    data7: "1000",
                    data8: "2021-12-08",
                    data9: "92.78",
                },
            ],
            currentPage2: 1,
        }
    },
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        }
    },
}
</script>
<style lang="less">
.equipmentCom8{
    .el-pagination{
        position: absolute;
        right: 5%;
        bottom: -38px;
    }
}
</style>