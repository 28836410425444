<template>
  <div style='width: 100%;height: 100%;'>
    <div class='board-head' style="height: 100%;">
      <img style="position: absolute;top: 14px;" @mouseover="mousedown($event)" @mouseout="mouseup" src="@/assets/imgs/tuo.png" />
      <div class='board-head-title' style='display:flex;align-items: center;height:20px;left: 22px;margin-bottom: 11px;    position: relative;top: -4px;'>
        <span v-if="whiteT">{{title}}</span>
        <span v-else style="color:white">{{title}}</span>
      </div>
      <div style="display: flex;flex-wrap: wrap;position: relative;top: -8px;">
        <slot name='headsearch'></slot>
        <div v-if="top" style='display: flex;align-items: center;font-size: 14px;margin-bottom: 6px;'>
          <div style='width: 33px;height: 20px;line-height: 20px;cursor: pointer;margin-right: 15px;' @click='timeChoseFn(1)'
              :class='{"active-time":activeTime===1}'>今日
          </div>
          <div style='width: 33px;height: 20px;line-height: 20px;cursor: pointer;margin-right: 15px;' @click='timeChoseFn(2)'
              :class='{"active-time":activeTime===2}'>本周
          </div>
          <div style='width: 33px;height: 20px;line-height: 20px;cursor: pointer;margin-right: 15px;' @click='timeChoseFn(3)'
              :class='{"active-time":activeTime===3}'>本月
          </div>
          <div style='width: 33px;height: 20px;line-height: 20px;cursor: pointer;margin-right: 15px;' @click='timeChoseFn(4)'
              :class='{"active-time":activeTime===4}'>全年
          </div>
        </div>
        <div v-if="top">

          <a-range-picker v-if="!time" :placeholder='["开始日期","结束日期"]' :value='rangeTime' @change='timeChangeFn' style="margin-right:30px;"/>
          <div v-else style="width:196px;height:32px;margin-right: 30px;"></div>
        </div>
      </div>
      <slot></slot>
      <div style='position: absolute;right: 18px;top: 15px;'>
        <el-popover
          placement="top-start"
          width="150"
          trigger="hover">
         <div style='padding: 8px'>
            <div style='width: 110px;height: 40px;display: flex;align-items: center;border-radius: 4px;' class='popover-item' @click="dele">
              <img style="width: 20px;margin-right:8px" src="@/assets/imgs/icon10.png" >
              删除
            </div>
           <!-- <div style='width: 110px;height: 40px;display: flex;align-items: center;border-radius: 4px;' class='popover-item' @click="copy">
            <img style="width: 20px;margin-right:8px" src="@/assets/imgs/icon8.png" >
             复制
           </div> -->
           <div style='width: 110px;height: 40px;display: flex;align-items: center;border-radius: 4px;' class='popover-item'>
            <img style="width: 20px;margin-right:8px" src="@/assets/imgs/icon9.png" @click="open()">
             配置
           </div>
         </div>
          <i class='el-icon-more' slot="reference"></i>
        </el-popover>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bulletinBoard',
  data() {
    return {
      Value:"",
      strValue:"",
      activeTime: 0,
      rangeTime: [],
      time:false
    }
  },
  props:['top','title','whiteT','id'],
  methods: {
    open(){
      console.log(this.title)
    },
    format(date) {
      let year = date.getFullYear()
      let mon = date.getMonth() + 1
      let day = date.getDate()
      if (mon < 10) {
        mon = '0' + mon
      }
      if (day < 10) {
        day = '0' + day
      }
      return `${year}-${mon}-${day}`
    },
    timeChangeFn(a,b){
        this.rangeTime = b
    },
    timeChoseFn(activeTime) {
      this.activeTime = activeTime
      let date = new Date()
      if (this.activeTime === 1) {
        this.time = true
        let year = date.getFullYear()
        let mon = date.getMonth() + 1
        let day = date.getDate()
        if (mon < 10) {
          mon = '0' + mon
        }
        if (day < 10) {
          day = '0' + day
        }
        this.rangeTime[0] = `${year}-${mon}-${day}`
        this.rangeTime[1] = `${year}-${mon}-${day}`

      } else if (this.activeTime === 2) {
        this.time = false
        const now = new Date()
        const nowTime = now.getTime()
        // getDay()返回0-6，其中0表示周日，需特殊处理
        const day = now.getDay() > 0 ? now.getDay() : 7 // 表示当前是周几
        const oneDayTime = 24 * 60 * 60 * 1000 // 一天的总ms

        // 本周一时间戳
        const MondayTime = nowTime - (day - 1) * oneDayTime

        // 本周日时间戳
        const SundayTime = nowTime + (7 - day) * oneDayTime

        // 格式化时间
        const monday = new Date(MondayTime)
        const sunday = new Date(SundayTime)

        // 可以通过自定义函数（format）格式化时间戳
        this.rangeTime[0] = `${this.format(monday)}`
        this.rangeTime[1] = `${this.format(sunday)}`

      } else if (this.activeTime === 3) {
        this.time = false
        let year = date.getFullYear()
        let mon = date.getMonth() + 1

        let currentMonth = date.getMonth()

        let nextMonth = ++currentMonth

        let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1).getTime()

        let oneDay = 1000 * 60 * 60 * 24

        // 可以通过自定义函数（format）格式化时间戳
        this.rangeTime[0] = `${year}-${mon}-01 `
        this.rangeTime[1] = `${this.format(new Date(nextMonthFirstDay - oneDay))}`

      } else if (this.activeTime === 4) {
        this.time = false
        let year = date.getFullYear()
        this.rangeTime[0] = `${year}-01-01`
        this.rangeTime[1] = `${year}-12-31`
      }
    },
    dele(){
      let arr = JSON.parse(this.$store.state.allwidget.value)
      for(let index in arr){
        if(arr[index].i == this.id){
          arr.splice(index,1)
        }
      }

      var url = Config.dev_url + "/api-apps/panel/updata";
      this.$axios
          .post(url, {
            id:this.$store.state.allwidget.id,
            value:JSON.stringify(arr)
          })
          .then((res) => {
          })
          .catch((err) => {});
      console.log(arr)
    },
    random(){
      var charactors="1234567890"; 
      var value='',i; 
      for(let j=1;j<=4;j++){ 
          i = parseInt(10*Math.random()); 　
          value = value + charactors.charAt(i);
        }
        return value
    },
    copy(){
      let arr = JSON.parse(this.$store.state.allwidget.value)
      for(let index in arr){
        if(arr[index].i == this.id){
          let item = arr[index]
          item.i = this.random()
          arr.push(item)
        }
      }
      var url = Config.dev_url + "/api-apps/panel/updata";
      this.$axios
          .post(url, {
            id:this.$store.state.allwidget.id,
            value:JSON.stringify(arr)
          })
          .then((res) => {
          })
          .catch((err) => {});
    },
    mousedown(){
      this.$emit('newmousedown',this.id)
    },
    mouseup(){
      this.$emit('newmouseup',this.id)
    }
  }
}
</script>

<style scoped lang='less'>
.board-head {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-content: flex-start;
  justify-content: space-between;
  padding: 16px;

  .board-head-title {
    // width: 150px;
    height: 20px;
    font-size: 16px;
    font-family: ABBvoice_WCNSG_Md;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    line-height: 24px;
    position: relative;
    top: -2px;
  }
}

.active-time {
  color: #4371ff;
}

/deep/ .ant-calendar-picker {
  width: 196px;
}

.popover-item:hover{
  background: #3366ff;;
  color: #fff;
}

</style>
