<template>
    <div class="equipmentCom6">
        <el-table :data="tableData" style="width: 100%; height: 340px; overflow: auto;">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="描述">
                            <!-- <span>{{ props.row.name }}</span> -->
                            <div>{{ props.row.describe }}</div>
                        </el-form-item>
                        <el-form-item label="可能原因">
                            <span>{{ props.row.reason }}</span>
                        </el-form-item>
                        <el-form-item label="建议措施">
                            <span>{{ props.row.proposal }}</span>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column label="事件时间" prop="id">
            </el-table-column>
            <el-table-column label="严重性" prop="name">
            </el-table-column>
            <el-table-column label="类型" prop="category">
            </el-table-column>
            <el-table-column label="设备信息">
                <template slot-scope="scope">
                    <img src="" alt="">
                    <span>{{scope.row.desc}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作"> 
                <template>
                   <i class="el-icon-more"></i>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>
<script>
export default {
    data() {
        return {
            tableData: [{
                id: '刚刚',
                name: '预警',
                category: '自定义报警',
                desc: '设备1AT1_Trafo incoming',
                address: '上海市普陀区真北路',
                describe: "超负荷",
                reason: "投入设备过多",
                proposal: "切除无必要的设备"
            },{
                id: '刚刚',
                name: '预警',
                category: '自定义报警',
                desc: '设备1AT1_Trafo incoming',
                address: '上海市普陀区真北路',
                describe: "超负荷",
                reason: "投入设备过多",
                proposal: "切除无必要的设备"
            },{
                id: '刚刚',
                name: '预警',
                category: '自定义报警',
                desc: '设备1AT1_Trafo incoming',
                address: '上海市普陀区真北路',
                describe: "超负荷",
                reason: "投入设备过多",
                proposal: "切除无必要的设备"
            },{
                id: '刚刚',
                name: '预警',
                category: '自定义报警',
                desc: '设备1AT1_Trafo incoming',
                address: '上海市普陀区真北路',
                describe: "超负荷",
                reason: "投入设备过多",
                proposal: "切除无必要的设备"
            },]
        }
    },
}
</script>
<style lang="less">
.equipmentCom6 {
    .el-form--inline .el-form-item__content {
        display: block;
    }

    .demo-table-expand {
        display: flex;
        justify-content: space-around;
    }
}
</style>